<template>
	<CookieInformationBlocker
		class="c-cookie-information-blocker-custom"
		v-bind="{ ...$props, wrapper: 'div' }"
	>
		<template #default>
			<slot></slot>
		</template>
		<template #blocker="{ requiredCategories, cookieInformation }">
			<div
				class="
					c-cookie-information-blocker-custom__container
					flex flex-col
					rounded-md
					h-full
					items-start
					gap-layout-gutter
					w-full
					bg-primary-light
					p-md
				"
			>
				<BaseH2 class="flex items-center space-x-sm">
					<SvgWarningIcon class="w-sm text-text" />
					<span v-text="'Indhold blokeret'"></span>
				</BaseH2>

				<p class=">=1024:w-1/2">
					Dette element kan ikke vises, da du ikke har givet accept
					til følgende typer af cookies:
				</p>
				<div
					class="
						c-cookie-information-blocker-custom__info
						grid
						gap-layout-gutter
					"
				>
					<ul class="capitalize pl-sm">
						<li
							v-for="consent in requiredCategories.filter(
								(e) => !e.consentGiven
							)"
							:key="`consent-${consent.name}`"
							class="
								c-cookie-information-blocker-custom__item
								relative
								pl-xs
								text-body
							"
							v-text="
								{
									statistic: 'Statistiske',
									functional: 'Funktionelle',
									marketing: 'Marketing',
								}[consent.name]
							"
						></li>
					</ul>

					<p
						class="
							c-cookie-information-blocker-custom__instruction
							block
						"
					>
						Du kan ændre dine indstillinger ved at klikke på knappen
						herunder.
					</p>

					<BaseButton
						aria-label="Åbn cookie indstillinger"
						class="
							w-max
							h-max
							flex
							items-center
							justify-items-center
						"
						@click.stop="cookieInformation.show"
					>
						<p
							class="block"
							v-text="'Åbn cookie-indstillinger'"
						></p>
						<SvgCookieIcon
							fill="currentColor"
							class="hidden w-16 h-16 text-white"
						/>
					</BaseButton>
				</div>
			</div>
		</template>
	</CookieInformationBlocker>
</template>

<script>
import CookieInformationBlocker from '~/citi-baseline/components/CookieInformationBlocker';
import SvgWarningIcon from '~/assets/svgs/warning-icon.svg?inline';
import SvgCookieIcon from '~/assets/svgs/cookie-icon.svg?inline';
export default {
	name: 'CookieInformationBlockerCustom',
	components: { CookieInformationBlocker, SvgWarningIcon, SvgCookieIcon },

	props: {
		...CookieInformationBlocker.props,
	},
};
</script>

<style lang="postcss">
.c-cookie-information-blocker-custom {
	container: c-cookie-information-container / size;
}

@container c-cookie-information-container (height < 380px) {
	.c-cookie-information-blocker-custom__container {
		gap: 10px !important;
		padding: 25px !important;
	}
	.c-cookie-information-blocker-custom__container p {
		width: 100% !important;
	}

	.c-cookie-information-blocker-custom__info {
		gap: 10px !important;
	}

	.c-cookie-information-blocker-custom__container button {
		align-self: center;
	}
}
@container c-cookie-information-container (height < 250px) {
	.c-cookie-information-blocker-custom__info {
		grid-template-columns: 1fr 1fr;
	}
}

@container c-cookie-information-container ((height < 250px) and (width < 440px)) {
	.c-cookie-information-blocker-custom__container button p {
		display: none !important;
	}
	.c-cookie-information-blocker-custom__container button svg {
		display: block !important;
	}
	.c-cookie-information-blocker-custom__container button {
		padding: 10px !important;
		justify-self: center;
	}
}

@container c-cookie-information-container (height < 400px) {
	.c-cookie-information-blocker-custom__instruction {
		display: none !important;
	}
}

.c-cookie-information-blocker-custom__item:before {
	content: '';
	@apply absolute top-1/2 left-0;
	@apply transform -translate-y-1/2;
	@apply w-6 h-6 rounded-full bg-text;
}
</style>
